import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { contactSection } from "../content/Common"
import ContactSection from "../components/common/ContactSection"
import InnerHeader from "../components/molecules/InnerHeader"
import { contactPage } from "../content/ContactUs"

const IndexPage = () => (
  <Layout>
    <Seo title="Contact Us" />

    <InnerHeader title={contactPage.title} description={contactPage.description} googleReviewText={contactPage.googleReviewText} />

    <ContactSection data={contactSection} sectionTitleAlignment={`left`} />
  </Layout>
)

export default IndexPage
